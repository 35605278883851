// import { CometChat } from '@cometchat-pro/chat'
import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
// import PropTypes from 'prop-types'
import ky from 'ky'
import { CometChat } from '@cometchat-pro/chat'
import Loader from 'react-loader-spinner'

import { CometChatUI, CometChatMessages } from '../cometchat-pro-react-ui-kit/CometChatWorkspace/src'
import { COMETCHAT_CONSTANTS } from '../consts'
import * as actions from '../store/action'
import './ChatScreenStyles.scss'

const { REACT_APP_BACKEND_URL } = process.env

class ChatScreen extends Component {
  constructor() {
    super()
    this.state = {
      loggedIn: false,
      // user: null,
      userToChatWith: '',
      errorMessage: ''
    }
  }

  async componentDidMount() {
    // console.log({ env: process.env })
    // const { onLogin } = this.props
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    // console.log({ params })
    // this.props.getLoggedinUser();
    await this.verifyToken({ token: _.get(params, 'token') })

    try {
      const { user } = this.state
      // console.log('logging in ')
      await this.loginToCometChat({ uid: user.chatServiceId })
      // await this.setState({ user: await CometChat.getLoggedinUser() })
      await this.setState({ loggedIn: true })
    } catch (err) {
      // console.log({ err })
    }

    if (params.userToChatWith) {
      await this.setState({ userToChatWith: params.userToChatWith })
    }
  }

  async verifyToken({ token }) {
    const response = await ky.post(`${REACT_APP_BACKEND_URL}/v1/chat/verifyToken`, {
      json: { token }
    }).json()

    // console.log({ response })
    // await this.setState({ user: response.user })
    if (response.user && response.user.chatServiceId) {
      await this.setState({ user: response.user })
    } else {
      await this.setState({ errorMessage: 'Sorry! Chat has not been activated for your user yet. Please accept a connection to proceed.' })
    }
    return response
  }

  async loginToCometChat({ uid }) {
    await CometChat.login(uid, COMETCHAT_CONSTANTS.AUTH_KEY).then((user) => {
      if (user) {
        // console.log('logged in')
        this.setState({ loggedIn: true })
      } else {
        // console.log('failed login')
      }
    }).catch(error => {
      throw error
      // console.log('CometChatLogin Failed', error)
    })
  }

  chatComponentToRender() {
    const { userToChatWith } = this.state
    if (userToChatWith) {
      return <CometChatMessages chatWithUser={userToChatWith} />
    }

    return <CometChatUI friendsOnly />
  }

  render() {
    const {
      loggedIn,
      // user,
      errorMessage
    } = this.state

    if (errorMessage) {
      return (
        <React.Fragment>
          <p>{errorMessage}</p>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        {loggedIn
          ? this.chatComponentToRender()
          : (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Loader type="ThreeDots" color="#82cf8f" height="100" width="100" />
            </div>
          )}
      </React.Fragment>
    )
  }
}

ChatScreen.propTypes = {
  // onLogin: PropTypes.func.isRequired
  // verifyToken: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn
})

const mapDispatchToProps = (dispatch) => ({
  onLogin: (uid, authKey) => dispatch(actions.auth(uid, authKey))
  // verifyToken: (userId, token) => dispatch(actions.verifyToken(userId, token))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatScreen)
